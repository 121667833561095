import React, { useContext, useEffect, useRef, useState } from "react";
import { Transition } from "@headlessui/react";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";
import { Auth, Hub } from "aws-amplify";
import logo from "../../static/logo.png";
import { AppContext } from "../../context";
import { useClassStoreContext } from "../Shop/ClassStoreContext";
import { ShoppingBagIcon } from "@heroicons/react/24/outline";

const resourcesDropdownRoutes = [
  {
    route: "/casting-companies",
    title: "Casting Companies",
  },
  {
    route: "/tools-and-supplies",
    title: "Tools and Supplies",
  },
  {
    route: "/fun-stuff",
    title: "Fun Stuff",
  },
];
const aboutDropdownRoutes = [
  {
    route: "/about",
    title: "Wax Carvers",
  },
  {
    route: "/sandy",
    title: "Sandy",
  },
  {
    route: "/faq",
    title: "FAQ",
  },
  {
    route: "/contact",
    title: "Contact",
  },
];

const mobileMenuItems = [
  {
    title: "Home",
    url: "/",
  },
  { url: "/about", title: "About Wax Carvers" },
  { url: "/sandy", title: "About Sandy" },
  {
    title: "Classes",
    url: "/classes",
  },
  {
    title: "Blog",
    url: "/blog",
  },
  /*  {
      title: 'Tools & Materials',
      url: '/tools'
    },*/
  {
    title: "FAQ",
    url: "/faq",
  },
  {
    title: "Casting Companies",
    url: "/casting-companies",
  },
  {
    url: "/tools-and-supplies",
    title: "Tools and Supplies",
  },
  {
    url: "/fun-stuff",
    title: "Fun Stuff",
  },
  {
    title: "Contact",
    url: "/contact",
  },
];

const selectedRoute =
  "text-gray-900 focus:outline-none focus:border-blue-700 transition duration-150 ease-in-out border-blue-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium leading-5";
const unselectedRoute =
  "text-gray-700 hover:text-gray-700 hover:border-blue-700 focus:outline-none focus:text-gray-700 focus:border-blue-700 transition duration-150 ease-in-out inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium leading-5";
const selectedMobileRoute =
  "block pl-3 pr-4 py-2 border-l-4 border-blue-500 text-base font-medium text-blue-700 bg-blue-50 focus:outline-none focus:text-blue-800 focus:bg-blue-100 focus:border-blue-700 transition duration-150 ease-in-out";
const unselectedMobileRoute =
  "block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 transition duration-150 ease-in-out";

const DropDown = ({ title, selected, routes }) => {
  const [isOpen, setOpen] = useState(false);
  const wrapperRef = useRef(null);

  // below is the same as componentDidMount and componentDidUnmount
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setOpen(false);
    }
  };
  return (
    <div
      className="relative cursor-pointer h-full"
      onClick={() => setOpen(!isOpen)}
    >
      <span className={`${selected ? selectedRoute : unselectedRoute} h-full`}>
        <button
          type="button"
          className="inline-flex w-full rounded-md px-1 bg-white items-center text-sm
                  leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150"
          id="options-menu"
          aria-haspopup="true"
          aria-expanded="true"
        >
          {title}
          <svg
            className="-mr-1 ml-2 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </span>
      <Transition
        show={isOpen}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        {(ref) => (
          <div
            onClick={() => setOpen(false)}
            ref={ref}
            className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg z-50"
          >
            <div
              ref={wrapperRef}
              className="rounded-md bg-white ring-1 ring-black ring-opacity-5"
            >
              <div
                className="py-1"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="options-menu"
              >
                {routes.map((it) => (
                  <Link
                    role="menuitem"
                    key={it.route}
                    className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                    to={it.route}
                  >
                    {it.title}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        )}
      </Transition>
    </div>
  );
};

const Navigation = () => {
  const { user, getCurrentUser } = useContext(AppContext);
  const { openCart, cartOpen } = useClassStoreContext();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation();
  const showShoppingCart = false; // location.pathname.startsWith('/classes') || location.pathname.startsWith('/class/')
  const routeClasses = (loc) =>
    `${location.pathname === loc ? selectedRoute : unselectedRoute}`;
  const startsWithRouteClasses = (loc) =>
    `${
      location.pathname === loc || location.pathname.startsWith(loc)
        ? selectedRoute
        : unselectedRoute
    }`;
  const mobileRouteClasses = (loc) =>
    `${
      location.pathname === loc ? selectedMobileRoute : unselectedMobileRoute
    }`;
  const startsWithMobileRouteClasses = (loc) =>
    `${
      location.pathname === loc || location.pathname.startsWith(loc)
        ? selectedMobileRoute
        : unselectedMobileRoute
    }`;

  useEffect(() => {
    Hub.listen("auth", (data) => {
      const { payload } = data;
      if (payload.event === "signIn") {
        getCurrentUser();
        navigate("/app/my-profile");
      }
      if (payload.event === "signOut") {
        console.debug("a user has signed out!");
      }
    });
  }, [navigate, getCurrentUser]);
  const signOut = async () => {
    try {
      await Auth.signOut({ global: true });
      window.location = "/";
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  return (
    <nav className="bg-white border-b border-gray-200">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="sm:flex hidden">
            <Link to={"/"}>
              <div className="flex-shrink-0 flex items-center mt-2">
                <img
                  className="block h-12 w-auto"
                  width="100"
                  height="48"
                  src={logo}
                  alt="Wax Carvers Logo"
                />
              </div>
            </Link>
            <div className="hidden sm:-my-px sm:ml-6 space-x-8 sm:flex text-gray-800">
              <Link to={"/"} className={routeClasses("/")}>
                Home
              </Link>
              <DropDown
                title="About"
                routes={aboutDropdownRoutes}
                selected={aboutDropdownRoutes
                  .map((it) => it.route)
                  .includes(location.pathname)}
              />
              <Link
                to={"/classes"}
                className={startsWithRouteClasses("/classes")}
              >
                Classes
              </Link>
              <Link to={"/blog"} className={startsWithRouteClasses("/blog")}>
                Blog
              </Link>
              <DropDown
                title="Resources"
                routes={resourcesDropdownRoutes}
                selected={resourcesDropdownRoutes
                  .map((it) => it.route)
                  .includes(location.pathname)}
              />
              {user && (
                <Link
                  to={"/app/my-profile"}
                  className={startsWithRouteClasses("/app/my-profile")}
                >
                  My Classes
                </Link>
              )}
            </div>
          </div>
          <div className="hidden sm:ml-6 sm:flex sm:items-center sm:flex-1 justify-end">
            <div className="ml-3 relative">
              <div>
                {user ? (
                  <button
                    className="flex text-sm border-2 border-transparent rounded-full focus:outline-none focus:border-gray-300 transition duration-150 ease-in-out"
                    onClick={signOut}
                  >
                    Sign Out
                  </button>
                ) : (
                  <Link
                    to={"/app"}
                    className="flex text-sm border-2 border-transparent rounded-full focus:outline-none focus:border-gray-300 transition duration-150 ease-in-out"
                    id="user-menu"
                    aria-label="Log In"
                  >
                    Sign In
                  </Link>
                )}
              </div>
            </div>
          </div>
          {showShoppingCart && (
            <div className="hidden sm:ml-4 sm:flow-root lg:ml-6 mt-5">
              <a href="#" className="group -m-2 flex items-center p-2">
                <ShoppingBagIcon
                  className="h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
                <span className="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800">
                  0
                </span>
                <span className="sr-only">items in cart, view bag</span>
              </a>
            </div>
          )}
          {/* MOBILE */}
          <div className="-mr-2 flex flex-1 items-center sm:hidden justify-between">
            <button
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
            >
              <svg
                className="block h-6 w-6"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              <svg
                className="hidden h-6 w-6"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <Link to={"/"}>
              <div className="flex-shrink-0 flex items-center mt-2">
                <img
                  className="block h-12 w-auto"
                  width="100"
                  height="48"
                  src={logo}
                  alt="Wax Carvers Logo"
                />
              </div>
            </Link>
            {showShoppingCart && (
              <div className="sm:hidden flow-root mx-6 justify-items-center">
                <a
                  href="#"
                  onClick={() => openCart(!cartOpen)}
                  className="group -m-2 flex items-center p-2"
                >
                  <ShoppingBagIcon
                    className="h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  <span className="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800">
                    0
                  </span>
                  <span className="sr-only">items in cart, view bag</span>
                </a>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={mobileMenuOpen ? "block sm:block" : "hidden sm:hidden"}>
        <div className="pt-2 pb-3 space-y-1">
          {mobileMenuItems.map((it) => (
            <Link
              to={it.url}
              key={it.url}
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              className={mobileRouteClasses(it.url)}
            >
              {it.title}
            </Link>
          ))}
          {user && (
            <Link
              to={"/app/my-profile"}
              className={startsWithMobileRouteClasses("/app/my-profile")}
            >
              My Classes
            </Link>
          )}
        </div>
        <div className="pt-4 pb-3 border-t border-gray-200">
          <div
            className="mt-3 space-y-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="user-menu"
          >
            {user ? (
              <button
                className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 focus:outline-none focus:text-gray-800 focus:bg-gray-100 transition duration-150 ease-in-out"
                onClick={signOut}
              >
                Sign Out
              </button>
            ) : (
              <Link
                to={"/app"}
                className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 focus:outline-none focus:text-gray-800 focus:bg-gray-100 transition duration-150 ease-in-out"
                role="menuitem"
              >
                Sign In
              </Link>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
