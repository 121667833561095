import stackingrings from "../static/classes/stackingrings.png";
import charms from "../static/classes/charms.png";
import jan21 from "../static/classes/jan21.png";
import cabochonsettings from "../static/classes/cabochonsettings.png";
import repeatingpattern from "../static/classes/repeatingpattern.png";
import facetedsettings from "../static/classes/facetedsettings.png";
import solitairering from "../static/classes/solitairering.png";
import animals from "../static/classes/animals.png";
import signetrings from "../static/classes/signetrings.png";
import bangles from "../static/classes/bangles.png";
import rosecutrings from "../static/classes/rosecutrings.png";
import beads from "../static/classes/beads.png";
import separateparts from "../static/classes/separateparts.png";
import robot from "../static/classes/robot.png";
import flowers from "../static/classes/flowers.png";
import swivel from "../static/classes/swivel.png";
import softwax from "../static/classes/softwax.png";
import sep22 from "../static/classes/sep22.png";
import sheet from "../static/classes/sheet.png";
import voucher from "../static/classes/voucher.png";
import adventCalendar from "../static/classes/advent-calendar.png";
import adventCalendarExplorer from "../static/classes/advent-calendar-explorer.png";

export const overview = {
  classes: [
    {
      salesPageReleased: true,
      endDate: new Date("2020-11-01"),
      timeframe: "November 2020",
      title: "Stacking rings",
      description:
        "Make a set of stacking rings and learn all the basics of wax carving.",
      image: stackingrings,
      identifier: "stacking-rings",
      tags: ["beginner", "rings"],
      upgradeable: true,
    },
    {
      salesPageReleased: true,
      endDate: new Date("2020-11-30"),
      timeframe: "December 2020",
      title: "Charms",
      description:
        "Make a set of charms and learn to carve 3d shapes from wax.",
      image: charms,
      identifier: "charms",
      tags: ["beginner"],
      upgradeable: true,
    },
    {
      salesPageReleased: true,
      endDate: new Date("2020-12-31"),
      timeframe: "January 2021",
      title: "Bombe ring",
      identifier: "bombe-rings",
      description:
        "Make a bombe ring and learn to carve big rings that are comfortable to wear.",
      image: jan21,
      tags: ["beginner", "rings"],
      upgradeable: true,
    },
    {
      salesPageReleased: true,
      endDate: new Date("2021-01-31"),
      timeframe: "February 2021",
      title: "Rosecut and cabochon bezel settings",
      identifier: "rosecut-and-cabochon-bezel-settings",
      description:
        "Make settings for flat backed stones and learn to carve wax with precision.",
      image: cabochonsettings,
      tags: ["intermediate", "gemstones"],
    },
    {
      salesPageReleased: true,
      endDate: new Date("2021-03-01"),
      timeframe: "March 2021",
      title: "Faceted gemstone bezel settings",
      description:
        "Make settings for faceted stones and learn to carve wax with precision.",
      image: facetedsettings,
      identifier: "faceted-gemstone-bezel-settings",
      tags: ["intermediate", "gemstones"],
    },
    {
      salesPageReleased: true,
      endDate: new Date("2021-05-01"),
      timeframe: "April 2021",
      title: "Repeating pattern rings",
      description:
        "Make repeating pattern rings and learn to measure, mark, and carve patterns.",
      image: repeatingpattern,
      identifier: "repeating-pattern-rings",
      tags: ["advanced", "rings"],
    },
    {
      salesPageReleased: true,
      endDate: new Date("2021-06-01"),
      timeframe: "May 2021",
      title: "Solitaire ring with fitted bands",
      description:
        "Make a solitaire ring with fitted bands and learn to carve perfectly fitted rings.",
      image: solitairering,
      identifier: "solitaire-ring-with-fitted-bands",
      tags: ["intermediate", "rings", "gemstones"],
    },
    {
      salesPageReleased: true,
      endDate: new Date("2021-06-01"),
      timeframe: "June 2021",
      title: "Animal carving",
      description:
        "Make a set of animal carvings and learn to carve an animal at different difficulty levels.",
      image: animals,
      identifier: "animal-carving",
      tags: ["intermediate"],
    },
    {
      salesPageReleased: true,
      endDate: new Date("2021-07-01"),
      timeframe: "July 2021",
      title: "Signet ring",
      description:
        "Make a signet ring and learn to carve a classic, with some twists.",
      image: signetrings,
      identifier: "signet-ring",
      tags: ["intermediate", "rings"],
    },
    {
      salesPageReleased: true,
      endDate: new Date("2021-08-01"),
      timeframe: "August 2021",
      title: "Bangle",
      description:
        "Make a set of bangles and learn to carve the ultimate summer accessory.",
      image: bangles,
      identifier: "bangle",
      tags: ["intermediate"],
    },
    {
      salesPageReleased: true,
      endDate: new Date("2021-09-01"),
      timeframe: "September 2021",
      title: "Rosecut ring",
      description:
        "Make a ring with a rosecut stone and learn to carve unique rings around unique stones.",
      image: rosecutrings,
      identifier: "rosecut-ring",
      tags: ["intermediate", "rings", "gemstones"],
    },
    {
      salesPageReleased: true,
      endDate: new Date("2021-10-01"),
      timeframe: "October 2021",
      title: "Bead caps",
      description:
        "Make bead caps (and more!) and learn to carve wax to go around beads.",
      image: beads,
      identifier: "bead-caps",
      tags: ["intermediate", "gemstones"],
    },
    {
      salesPageReleased: true,
      endDate: new Date("2021-11-01"),
      timeframe: "November 2021",
      title: "Ring from separate parts",
      description:
        "Make a ring from separate parts and learn to carve pieces that fit together perfectly.",
      image: separateparts,
      identifier: "ring-from-separate-parts",
      tags: ["advanced", "rings", "gemstones"],
    },
    {
      salesPageReleased: true,
      endDate: new Date("2021-12-01"),
      timeframe: "December 2021",
      title: "Robot",
      description:
        "Make a robot and learn to carve different hinged parts that can move. ",
      image: robot,
      identifier: "robot",
      tags: ["intermediate"],
    },
  ],
};

export const classes2022 = [
  {
    salesPageReleased: true,
    endDate: new Date("2022-03-10"),
    timeframe: "March 2022",
    title: "Flowers",
    description:
      "Make flowers and learn to carve settings inside a bigger piece.",
    image: flowers,
    tags: ["intermediate", "gemstones"],
  },
  {
    salesPageReleased: true,
    endDate: new Date("2022-06-31"),
    timeframe: "2023",
    title: "Sheet & wire",
    description:
      "Make pieces from sheet and wire wax and learn how to shape these soft waxes.",
    image: sheet,
    tags: ["beginner"],
  },
  {
    salesPageReleased: true,
    endDate: new Date("2022-06-31"),
    timeframe: "2023",
    title: "Swivel rings",
    description:
      "Make a swivel ring and learn to carve rings with a central part that moves.",
    image: swivel,
    tags: ["intermediate"],
  },
  {
    salesPageReleased: true,
    endDate: new Date("2022-12-31"),
    timeframe: "2024",
    title: "Soft wax bezel settings",
    identifier: "Soft-wax-bezels",
    description:
      "Make bezels from soft waxes and learn to shape soft wax around any type of stone.",
    image: softwax,
    tags: ["intermediate", "gemstones"],
  },
  {
    salesPageUrl: "/gift-card",
    salesPageReleased: true,
    endDate: new Date("2024-12-31"),
    timeframe: "December",
    title: "Gift Voucher",
    description:
      "Know someone that would love to take a class, but you’re not sure what class that is? \n" +
      "\n" +
      "Why not give them a gift voucher!\n",
    image: voucher,
    tags: [],
  },
  {
    salesPageUrl: "/advent-calendar",
    salesPageReleased: false,
    endDate: new Date("2024-12-31"),
    timeframe: "December",
    title: "Trailblazer Advent Calendar",
    additionalTitles: ["trailblazer advent calendar"],
    description:
      "A creative wax carving advent calendar? Yes please! 24 short classes covering a whole range of different topics. A perfect way to try different techniques and types of wax! ",
    image: adventCalendar,
    identifier: "advent-calendar-2022",
    type: "bundle",
    tags: ["beginner"],
  },
  {
    salesPageReleased: false,
    endDate: new Date("2024-12-31"),
    timeframe: "December",
    title: "Explorer Advent Calendar",
    additionalTitles: ["explorer advent calendar"],
    description:
      "A creative wax carving advent calendar? Yes please! 24 short classes covering a whole range of different topics. A perfect way to try different techniques and types of wax! ",
    image: adventCalendarExplorer,
    identifier: "advent-calendar-2023",
    type: "bundle",
    tags: ["beginner"],
  },
  {
    salesPageReleased: false,
    endDate: new Date("2022-09-31"),
    timeframe: "2024",
    title: "Claw setting",
    description:
      "Do you call them claws or prongs? Does it matter? We’re making some claw/prong settings!",
    image: sep22,
    tags: ["intermediate", "gemstones"],
  },
  {
    freebie: true,
    salesPageReleased: false,
    endDate: new Date("2024-12-31"),
    timeframe: "2024",
    title: "Bonus",
    description:
      "Bonus content for Advent Calendar early birds and repeats buyers",
    image: softwax,
    tags: ["intermediate"],
  },
];
